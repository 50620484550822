import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';
import localeEnglish from '@angular/common/locales/en';
import localeJapanese from '@angular/common/locales/ja';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

registerLocaleData(localeEnglish);
registerLocaleData(localeJapanese);

export const LOCALE_STORAGE_KEY = 'locale';
export const SUPPORTED_LOCALES = ['en', 'ja'];

@Injectable()
export class LocaleService {
	// @ts-ignore
	private _locale: string;
	private locale$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(private translateService: TranslateService) {
		this.setupTranslate();
	}

	getLocale(): string {
		// eslint-disable-next-line no-undef
		this._locale = window.localStorage.getItem(LOCALE_STORAGE_KEY) || environment.locale;
		return this._locale;
	}

	setLocale(locale: string): void {
		// eslint-disable-next-line no-undef
		window.localStorage.setItem(LOCALE_STORAGE_KEY, locale);
		this._locale = locale;
		this.initializeTranslateForLocale(locale);
		this.locale$.next(locale);
	}

	private initializeTranslateForLocale(locale: string): void {
		this.translateService.use(locale);
	}

	public getSupportedLocales(): Array<string> {
		return this.translateService.getLangs();
	}

	private setupTranslate(): void {
		this.getLocale();
		this.translateService.addLangs(SUPPORTED_LOCALES);
		this.translateService.setDefaultLang(this._locale);
		this.locale$.next(this._locale);
	}

	public onLocaleChange(): Observable<string> {
		return this.locale$.asObservable();
	}
}
